.App {
  text-align: center;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family:avertaBlack;
  src: url(../public/AvertaStd-Black.ttf);
}
.averta {
  font-family: avertaBlack!important;
}

@font-face {
  font-family:avertaBlackItalic;
  src: url(../public/AvertaStd-BlackItalic.ttf);
}
.avertaBlackItalic {
  font-family: avertaBlackItalic!important;
}

@font-face {
  font-family:avertaBold;
  src: url(../public/AvertaStd-Bold.ttf);
}
.avertaBold {
  font-family: avertaBold!important;
}
@font-face {
  font-family:avertaBoldItalic;
  src: url(../public/AvertaStd-BoldItalic.ttf)
}
.avertaBoldItalic {
  font-family: avertaBoldItalic !important;
}

@font-face {
  font-family:avertaExtraBold;
  src: url(../public/AvertaStd-ExtraBold.ttf)
}
.avertaExtraBold {
  font-family: avertaExtraBold !important;
}

@font-face {
  font-family:avertaExtraBoldItalic;
  src: url(../public/AvertaStd-ExtraBoldItalic.ttf)
}
.avertaExtraBoldItalic {
  font-family: avertaExtraBoldItalic !important;
}

@font-face {
  font-family:avertaExtraThin;
  src: url(../public/AvertaStd-Extrathin.ttf)
}
.avertaExtraThin {
  font-family: avertaExtraThin !important;
}
@font-face {
  font-family:avertaSTDLight;
  src: url(../public/AvertaStd-Semibold.ttf)
}
.avertaSTDLight {
  font-family: avertaSTDLight !important;
}
@font-face {
  font-family:avertaSTDSemiBold;
  src: url(../public/AvertaStd-Semibold.ttf)
}
.avertaSTDSemiBold {
  font-family: avertaSTDSemiBold !important;
}
@font-face {
  font-family:avertaRegular;
  src: url(../fonts/static/Montserrat-Regular.ttf)

  /* src: url(../public/AvertaStd-Regular.ttf) */
}
.avertaRegular {
  font-family: avertaRegular !important;
}
@font-face {
  font-family:avertaRegularBold;
  src: url(../fonts/static/Montserrat-SemiBold.ttf)

  /* src: url(../public/AvertaStd-Regular.ttf) */
}
.avertaRegularBold {
  font-family: avertaRegularBold !important;
}
/* Poppins  */
@font-face {
  font-family:poppinsRegularBold;
  src: url(../fonts/Rubik//Rubik-Bold.ttf)

  /* src: url(../public/AvertaStd-Regular.ttf) */
}
.poppinsRegularBold {
  font-family: poppinsRegularBold !important;
}
@font-face {
  font-family:poppinsSemiRegularBold;
  src: url(../fonts/Rubik/Rubik-SemiBold.ttf)

  /* src: url(../public/AvertaStd-Regular.ttf) */
}
.poppinsSemiRegularBold {
  font-family: poppinsSemiRegularBold !important;
}
@font-face {
  font-family:poppinsLight;
  src: url(../fonts/Rubik/Rubik-Light.ttf)

  /* src: url(../public/AvertaStd-Regular.ttf) */
}
.poppinsLight {
  font-family: poppinsLight !important;
}
@font-face {
  font-family:lexendLight;
  src: url(../fonts/Lexend/Lexend/Lexend-Light.ttf)

  /* src: url(../public/AvertaStd-Regular.ttf) */
}
.lexendLight {
  font-family: lexendLight !important;
}






