.avatar-large-screen {
    display: block;
}
.avatar-small-screen {
    display: none;
}

.header {
    font-size: 44px;
    font-weight: 500;
    letter-spacing: 2%;
    color: #0C0C0C;
    text-shadow: 4px 4px 2px rgba(151, 151, 151, 0.6), 0 0 5px #F5BC01;
    margin-block: 6%;
    margin-bottom: 1%;
    width: 100%;
    text-transform: capitalize;
}

.paragraphtext {
    color: #888888;
    font-weight: 700;
    max-width: 80%;
}

@media screen and (max-width: 600px) {
    .avatar-large-screen {
        display: none;
    }
    .avatar-small-screen {
        display: block;
    }

    .header {
        font-size: 30px;
        font-weight: 500;
        letter-spacing: 2%;
        color: #0C0C0C;
        text-shadow: 4px 4px 2px rgba(151, 151, 151, 0.6), 0 0 5px #F5BC01;
        margin-block: 6%;
        margin-bottom: 1%;
        width: 100%;
        text-transform: capitalize;
    }
}